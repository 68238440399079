import { useAppKitProvider, useAppKitAccount } from "@reown/appkit/react";
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import {Header} from "../components/Header";
import {Outlet} from "react-router-dom";
import {Footer} from "../components/Footer";
import { useEffect } from "react";
import { useAuthStore } from "../store";

export const AppLayout = () => {
    const {
        setWallet,
        checkConnection
    } = useAuthStore(state => state);

    const { address } = useAppKitAccount();

    useEffect(() => {
        if(address) {
            setWallet(address)
        }
    }, [address])

    useEffect(() => {
        checkConnection()
    }, [])
    
    // const { walletProvider } = useAppKitProvider('eip155')

    // async function setProviderAndSigner() {
    //     const provider = new BrowserProvider(walletProvider)
        
    //     const signer = await provider.getSigner()
        
    //     setProvider(provider, signer)
    // }
    // useEffect(() => {
    //     setAddress(address)
    //     setIsConnected(isConnected)
    // }, [status])

    // useEffect(() => {
    //     if(isConnected) {
    //         setProviderAndSigner()
    //     }
    // }, [isConnected])
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}