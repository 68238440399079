import sprite from "../../../assets/sprite/diamond.svg";

export const Head = () => {
    return (
        <section className="head">
            <div className="head__title subtitle testhidetext topxtx__premint" style={{maxWidth: '1105px'}}>Auction Starting Soon</div>
            <div className="head__info">
                <ul className="head__list">
                    <li className="head__item new-first-info">
                        <div className="head__item-count descr">5,555</div>
                        <div className="head__item-status">NFT LEFT TO MINT</div>
                    </li>

                    <li className="head__item">
                        <div className="head__item-count head__item-count--common descr">3,500</div>
                        <div className="head__item-status card__status common">
                            <img src={sprite} style={{
                                width: '10px',
                                height: '10px',
                            }} alt=""/>
                            <span>common</span>
                        </div>
                    </li>

                    <li className="head__item">
                        <div className="head__item-count head__item-count--uncommon descr">1,500</div>
                        <div className="head__item-status card__status uncommon">
                            <img src={sprite} style={{
                                width: '10px',
                                height: '10px',
                            }} alt=""/>
                            <span>uncommon</span>
                        </div>
                    </li>
                    <li className="head__item">
                        <div className="head__item-count head__item-count--rare descr">500 </div>
                        <div className="head__item-status card__status rare">
                            <img src={sprite} style={{
                                width: '10px',
                                height: '10px',
                            }} alt=""/>
                            <span>rare</span>
                        </div>
                    </li>
                    <li className="head__item">
                        <div className="head__item-count head__item-count--epic descr">55 </div>
                        <div className="head__item-status card__status epic">
                            <img src={sprite} style={{
                                width: '10px',
                                height: '10px',
                            }} alt=""/>
                            <span>epic</span>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}