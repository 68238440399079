import copy from "../../assets/sprite/copy.svg";

export const Referral = ({info, hash, openModal}) => {
    const bid = +info[0] ;
    const copyToClipboard = async () => {
        try {
            if(bid) {
                await navigator.clipboard.writeText(`https://degy.io/?ref=${hash}`);

            }
        } catch (err) {
        }
    };
    return (
        <section className="referral-bid">
            <div className="referral-bid__container">
                <div className="referral-bid__left-block">
                    <div>
                        <p className="referral-bid__title">Auction <span>Referral Program</span></p>
                        <p className="referral-bid__text">
                            Earn ETH bonuses and DEGY Points by referring friends to participate in the DEGY Auction.
                            Unlock rewards and grow your influence with our two-tier referral system!
                        </p>
                        {bid === 0 &&
                            <p className="referral-bid__text">
                                ⚠️ Once you’ve placed your first bid, your unique referral link
                                will be generated and
                                activated. Share it with your network and start earning today!
                            </p>
                        }

                    </div>
                    <button className="content__more" onClick={openModal}>
                        <span>More about DEGY NFT Auction</span>
                        <img src="/static/media/question.106367a2131294dcec68ecedbbc6e927.svg" alt="" />
                    </button>
                </div>
                <div className="referral-bid__right-block">
                    <div className="referral-bid__link">
                        <p>Your Referral Link</p>
                        <div className="referral-bid__input">
                            {bid === 0 ?
                                <p className="notActive">
                                    Referral link unlocks after your first bid
                                </p>
                                :
                                <p className="active">{`https://degy.io/?ref=${hash}`}</p>
                            }

                            <button
                                onClick={copyToClipboard}
                            >
                                <img src={copy} alt="" />
                            </button>
                        </div>
                    </div>
                    <div className="referral-bid__dashboard">
                        <p>Referral Dashboard</p>
                        <div className="referral-bid__table">
                            <div className="referral-bid__item">
                                <p className="left">Total Frens</p>
                                <p className="referral-bid__line"></p>
                                <p  className="right">{Math.trunc(info[1])}</p>
                            </div>
                            <div className="referral-bid__item">
                                <p className="left">Level 1 Frens</p>
                                <p className="referral-bid__line"></p>

                                <p  className="right">{Math.trunc(info[2])}</p>
                            </div>
                            <div className="referral-bid__item">
                                <p className="left">Level 2 Frens</p>
                                <p className="referral-bid__line"></p>

                                <p  className="right">{Math.trunc(info[3])}</p>
                            </div>
                            <div className="referral-bid__item">
                                <p className="left">DEGY Points</p>
                                <p className="referral-bid__line"></p>

                                <p  className="right">{Math.trunc(info[4])}</p>
                            </div>
                            <div className="referral-bid__item">
                                <p className="left">Fren Bonus</p>
                                <p className="referral-bid__line"></p>

                                <p  className="right">{Math.trunc(info[5])} ETH</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}