import { createAppKit } from '@reown/appkit/react'
import { EthersAdapter } from '@reown/appkit-adapter-ethers'
import { arbitrum, mainnet, bscTestnet } from '@reown/appkit/networks'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RoutesConfig } from '../config/routes';
import '../sass/style.scss';
import 'swiper/scss';

const projectId = '92e2ac0564b2a0531bcd8afa83b9544f';

const networks = [arbitrum, mainnet, bscTestnet]

const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://degy.io', 
  icons: ['https://avatars.degy.io']
}

createAppKit({
  adapters: [new EthersAdapter()],
  networks,
  metadata,
  projectId,
  enableCoinbase: false,
  themeMode: 'light',
  features: {
    email: false,
    socials: false,
    emailShowWallets: false,
    analytics: false 
  }
})

function App() {
    return (
      <RouterProvider router={createBrowserRouter(RoutesConfig)} />
    );
}

export default App;
