import Close from '../../assets/sprite/close.svg';
import {useEffect, useRef, useState} from "react";
import Loader from '../../assets/loader.svg';
import Success from '../../assets/success.svg';

export const ClaimModal = ({isOpen, onClose, modalState}) => {
    const modalRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            modalRef.current && !modalRef.current.contains(event.target)
        ) {
            onClose();
        }
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const response = await invest(ref, inputValue);

    //         if(response) {
    //             setModalState('load')
    //         }
    //         const receipt = await response.wait();
    //         if(receipt) {
    //             setModalState('success')
    //             getUserBoard()
    //             getUserInfo()
    //         } else {
    //             setModalState('error')
    //         }
    //     } catch {
    //         setModalState('error')
    //     }

    // }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <>
            <div className={isOpen ? "modal is-open" : 'modal'} >
                <div  className={isOpen ? "modal__container modal-open fade animate-open" : 'modal__container'} data-animation="fade" data-speed="300" data-target="mint" ref={modalRef}>
                    {modalState === 'load' && <div className="modal__mint load-modal">
                        <div className="modal__close modal-close" onClick={onClose}>
                            <img src={Close} alt="" style={{
                                height: '16px',
                                width: '16px'
                            }}/>
                        </div>
                        <img src={Loader} alt="" className="modal__loader load"/>
                        <div>
                            <p className="modal__load-title">Transaction is being…</p>
                            <p className="modal__load-text">processed on the blockchain, please wait.</p>
                        </div>

                    </div>
                    }

                    {modalState === 'success' &&
                        <div className="modal__mint load-modal">
                            <div className="modal__close modal-close" onClick={onClose}>
                                <img src={Close} alt="" style={{
                                    height: '16px',
                                    width: '16px'
                                }}/>
                            </div>
                            <img src={Success} alt="" className="modal__loader"/>
                            <div>
                                <p className="modal__load-title">Transaction has been successfully…</p>
                                <p className="modal__load-text">sent and is now being confirmed</p>
                            </div>
                            <div className="modal__button" onClick={onClose}>
                                OK
                            </div>
                        </div>
                    }

                    {modalState === 'error' &&
                        <div className="modal__mint load-modal">
                            <div className="modal__close modal-close" onClick={onClose}>
                                <img src={Close} alt="" style={{
                                    height: '16px',
                                    width: '16px'
                                }}/>
                            </div>
                            <div>
                                <p className="modal__load-title">something went wrong</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}