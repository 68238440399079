import {AccordionItem} from "./AccordionItem";
import {useState} from "react";

export const Accordion = () => {
    const items = [
        { title: 'What is DEGY.IO and what do you offer?', content: 'DEGY is a platform combining a unique NFT collection and an indie blockchain game with a universe of Delivery Guys. Minted NFTs will be required to participate in the game and send Delivery Guys on missions. The more NFT you have, the more tasks you can accomplish in the Game.' },
        { title: 'What is a DEGY NFT Collection?', content: 'DEGY Collection features 6,000 unique NFTs. 5,000 are allocated for the public sale. 1,000 are reserved for the Private sale. When you mint an NFT, it is automatically generated for you. Features of your NFT character are selected randomly, as well as its rareness. The odds of minting a common NFT are better. However, if you are lucky enough, you can obtain an Uncommon, Rare, or even an Epic one' },
        { title: 'What is a DEGY GAME?', content: 'DEGY Game is an indie multiplayer blockchain game for DEGY NFT holders. To get access to the game, a player must have at least one DEGY NFT. Each NFT is a game character. Players can send their Delivery Guys on missions and get rewarded with in-game tokens.' },
        { title: 'How to purchase NFT?', content: 'At the moment, the only way to purchase DEGY NFT is to participate in the official Private Sale and Public Sale. After that, NFT will be listed on NFT marketplaces and traded at market prices.' },
        { title: 'What’s next?', content: 'DEGY NFT Sale is the first step in an exciting journey we’re taking. The next step is the Game release. DEGY Roadmap also includes a list of future additions and scenarios to keep the players motivated. We plan to expand the game universe, as well as add new forms of player interaction and reward mechanics. And NFT will be the key to all future projects.' },
    ];

    const [openIndex, setOpenIndex] = useState(null);

    const handleItemClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="facts__tabs-content facts__tabs-content--active" data-tabs-target="general">
            <div className="tabs__content-inner">
                <div className="facts__accordion accordion" data-spollers="" data-one-spoller="">
                    {items.map((item, index) => (
                        <AccordionItem
                            key={index}
                            title={item.title}
                            content={item.content}
                            isOpen={openIndex === index}
                            onClick={() => handleItemClick(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};