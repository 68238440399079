import {useRef} from "react";

export const AccordionItem = ({ title, content, isOpen, onClick }) => {
    const contentRef = useRef(null);
    return (
        <div className="facts__accordion-item accordion__item">
            <button
                tabIndex="-1"
                type="button"
                data-spoller=""
                className={isOpen ? "facts__accordion-title _active" : "facts__accordion-title"}
                onClick={onClick}
            >
                <span>{title}</span>
            </button>
            <div
                 className={`facts__accordion-text ${isOpen ? 'open' : ''}`}
                 style={{ maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : '0px' }}
                 ref={contentRef}
            >
                {content}
            </div>
        </div>
    );
};