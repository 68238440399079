export const checkAndSwitchNetwork = async () => {
    try {
        const chainId = parseInt(await window.ethereum.request({ method: "eth_chainId" }), 16);

        if (chainId === 56) {
            console.log("User is connected to the BNB Smart Chain Mainnet");
        } else if (chainId === 97) {
            console.log("User is connected to the BNB Smart Chain Testnet");
        } else {
            console.log("Switching to BSC Testnet as the user is not on the correct network...");

            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: "0x61",
                    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
                    chainName: "BNB Smart Chain Testnet",
                    nativeCurrency: {
                        name: "BNB",
                        symbol: "tBNB",
                        decimals: 18
                    },
                    blockExplorerUrls: ["https://testnet.bscscan.com/"]
                }]
            });
        }
    } catch (error) {
        console.error("Error while switching networks:", error);
    }
};