import ProfileIcon from '../../assets/profile.png';
// import NFT4 from '../../assets/nfts/nft4.png';
import Copy from '../../assets/copy.svg';
// import Diamond from '../../assets/sprite/diamond.svg';
import {useEffect, useState} from "react";
import {useAuthStore} from "../../store";
import {NavLink} from "react-router-dom";

export const Profile = () => {
    const [, setCopySuccess] = useState('');
    const [, setCopyRefferalSuccess] = useState('');
    
    const textToCopy = '0xs676a9a...4b8E';
    const refferalTextToCopy = 'deliverygus.?ref=0x7a98s898cas...';

    const {
        getWalletNFTs,
        connectToContract,
        address
    } = useAuthStore(state => state);

    const copyToClipboard = async (text, setCopy) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopy('Copied!');
        } catch (err) {
            setCopy('Failed to copy!');
        }
    };

    useEffect(() => {
        if (address) {
            getWalletNFTs();
        }
    }, [address]);

    return (
        <main>
            <section className="head head__small">
                <div className="head__title subtitle">Profile</div>
                <div className="head__profile-info profile__info">
                    <div className="profile__info-details">
                        <div className="profile__details">
                            <div className="profile__avatar">
                                <img className='profile__avatar-img' width='78' height='78' src={ProfileIcon} alt=' '/>
                            </div>
                            <div className="profile__details-status">
                                <div className="profile__wallet-id">
                                    <span>{address}</span>
                                    <img src={Copy} alt="" onClick={() => copyToClipboard(textToCopy, setCopySuccess)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile__info-nfts">
                        <ul className="profile__nfts-list">
                            <li className="profile__nft-item">
                                <div className="profile__nft-name">Common</div>
                                <div className="profile__nft-detail">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_388_9139)">
                                            <path d="M11.8139 1.44564L6.60138 6.98064L7.76575 1.44564L11.8139 1.44564ZM16.207 1.44564L17.3939 6.98064L12.2376 1.44564L16.207 1.44564ZM17.1558 8.16001L6.8395 8.16001L11.9995 2.61189L17.1558 8.16001ZM5.38075 8.16001H0.603253L6.73263 1.71189L5.38075 8.16001ZM5.437 9.12001L10.1076 21.435L0.567627 9.12001H5.437ZM6.4795 9.12001L17.5195 9.12001L11.9995 23.4319L6.4795 9.12001ZM18.6145 8.16001L17.2383 1.68564L23.3564 8.16001L18.6145 8.16001ZM13.8951 21.4256L18.5639 9.12001L23.4145 9.12001L13.8951 21.4256Z" fill="#96A2B0"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_388_9139">
                                                <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span className="profile__nft-count">0</span>
                                </div>
                            </li>
                            <li className="profile__nft-item">
                                <div className="profile__nft-name">Unommon</div>
                                <div className="profile__nft-detail">
                                    <svg width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.0639 1.44564L6.85138 6.98064L8.01575 1.44564L12.0639 1.44564ZM16.457 1.44564L17.6439 6.98064L12.4876 1.44564L16.457 1.44564ZM17.4058 8.16001L7.0895 8.16001L12.2495 2.61189L17.4058 8.16001ZM5.63075 8.16001H0.853253L6.98263 1.71189L5.63075 8.16001ZM5.687 9.12001L10.3576 21.435L0.817627 9.12001H5.687ZM6.7295 9.12001L17.7695 9.12001L12.2495 23.4319L6.7295 9.12001ZM18.8645 8.16001L17.4883 1.68564L23.6064 8.16001L18.8645 8.16001ZM14.1451 21.4256L18.8139 9.12001L23.6645 9.12001L14.1451 21.4256Z" fill="#49CF6F"/>
                                    </svg>
                                    <span className="profile__nft-count">0</span>
                                </div>
                            </li>
                            <li className="profile__nft-item">
                                <div className="profile__nft-name">Rare</div>
                                <div className="profile__nft-detail">
                                    <svg width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_388_9129)">
                                            <path d="M12.3138 1.44564L7.10132 6.98064L8.26569 1.44564L12.3138 1.44564ZM16.7069 1.44564L17.8938 6.98064L12.7376 1.44564L16.7069 1.44564ZM17.6557 8.16001L7.33944 8.16001L12.4994 2.61189L17.6557 8.16001ZM5.88069 8.16001H1.10319L7.23257 1.71189L5.88069 8.16001ZM5.93694 9.12001L10.6076 21.435L1.06757 9.12001H5.93694ZM6.97944 9.12001L18.0194 9.12001L12.4994 23.4319L6.97944 9.12001ZM19.1144 8.16001L17.7382 1.68564L23.8563 8.16001L19.1144 8.16001ZM14.3951 21.4256L19.0638 9.12001L23.9144 9.12001L14.3951 21.4256Z" fill="#46D1E5"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_388_9129">
                                                <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span className="profile__nft-count">0</span>
                                </div>
                            </li>
                            <li className="profile__nft-item">
                                <div className="profile__nft-name">Epic</div>
                                <div className="profile__nft-detail">
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_388_9149)">
                                            <path d="M12.5638 1.44564L7.35132 6.98064L8.51569 1.44564L12.5638 1.44564ZM16.9569 1.44564L18.1438 6.98064L12.9876 1.44564L16.9569 1.44564ZM17.9057 8.16001L7.58944 8.16001L12.7494 2.61189L17.9057 8.16001ZM6.13069 8.16001H1.35319L7.48257 1.71189L6.13069 8.16001ZM6.18694 9.12001L10.8576 21.435L1.31757 9.12001H6.18694ZM7.22944 9.12001L18.2694 9.12001L12.7494 23.4319L7.22944 9.12001ZM19.3644 8.16001L17.9882 1.68564L24.1063 8.16001L19.3644 8.16001ZM14.6451 21.4256L19.3138 9.12001L24.1644 9.12001L14.6451 21.4256Z" fill="#E562DF"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_388_9149">
                                                <rect width="24" height="24" fill="white" transform="translate(0.75)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span className="profile__nft-count">0</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="content__profile">
                <div className="container">
                    <div className="content__inner">
                        <div className="content__tabs">
                            <ul className="content__tabs-list">
                                <li className="content__tabs-item">
                                    <button className="content__tabs-btn content__tabs-btn--active" data-tabs-path="all">All NFTs</button>
                                </li>
                            </ul>

                            <div className="content__tabs-wrapper">
                                <div className="content__tabs-inner">
                                    <div className="content__tabs-content content__tabs-content--active" data-tabs-target="all">
                                        <div className="content__tabs-inner">
                                            <div className="content__tabs-cards">
                                                <div className="content__cards">

                                                    <div className="scroll__top" id="scrollTop">
                                                        <svg  width='36' height='36'>
                                                            <use href='img/sprite/sprite.svg#arrow-up'></use>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="content__tabs-content" data-tabs-target="onfarm">
                                        <div className="content__tabs-inner">
                                            <div className="content__tabs-cards">
                                                <div className="content__cards">
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="scroll__top" id="scrollTop">
                                                        <svg  width='36' height='36'>
                                                            <use href='img/sprite/sprite.svg#arrow-up'></use>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="content__tabs-content" data-tabs-target="from-mint">
                                        <div className="content__tabs-inner">
                                            <div className="content__tabs-cards">
                                                <div className="content__cards">
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="scroll__top" id="scrollTop">
                                                        <svg  width='36' height='36'>
                                                            <use href='img/sprite/sprite.svg#arrow-up'></use>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="content__tabs-content" data-tabs-target="from-airdrop">
                                        <div className="content__tabs-inner">
                                            <div className="content__tabs-cards">
                                                <div className="content__cards">
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card__image">
                                                            <img className='card__img' width='211' height='211' src='img/nfts/nft4.png' alt=''/>
                                                        </div>
                                                        <div className="card__info">
                                                            <div className="card__series">Delivery guys</div>
                                                            <div className="card__title">Delivery Guy #12001</div>
                                                            <div className="card__divider"></div>
                                                            <div className="card__footer">
                                                                <div className="card__status uncommon">
                                                                    <svg  width='10' height='10'>
                                                                        <use href='img/sprite/sprite.svg#diamond'></use>
                                                                    </svg>
                                                                    <span>uncommon</span>
                                                                </div>
                                                                <div className="card__price">
                                                                    <svg  width='13' height='13'>
                                                                        <use href='img/sprite/sprite.svg#bnb'></use>
                                                                    </svg>
                                                                    <span>0,989</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="scroll__top" id="scrollTop">
                                                        <svg  width='36' height='36'>
                                                            <use href='img/sprite/sprite.svg#arrow-up'></use>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <aside className="aside">
                                    <div className="aside__box">
                                        <div className="aside__title">My refferal link</div>
                                        <div className="aside__refferal">
                                            <div className="aside__refferal-id">{refferalTextToCopy}</div>
                                            <img src={Copy} alt=" " onClick={() => copyToClipboard(refferalTextToCopy, setCopyRefferalSuccess)}/>
                                        </div>
                                    </div>
                                    <NavLink
                                        to="/mint"
                                        className="aside__button"
                                    >
                                        <span>Go to Auction</span>
                                    </NavLink>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}