import IMG1 from '../../assets/img1.png';
import IMG2 from '../../assets/img2.png';
import IMG3 from '../../assets/img3.png';
import IMG4 from '../../assets/img4.png';
import IMG5 from '../../assets/img5.png';
import IMG6 from '../../assets/img6.png';
import BG1 from '../../assets/bg1.png';
import BG2 from '../../assets/bg2.png';
import BG3 from '../../assets/bg3.png';
import union from '../../assets/sprite/union.svg';
import {SwiperSlide, Swiper} from "swiper/react";
import { Autoplay } from 'swiper/modules';

export const Game = () => {
    const slides = Array(10).fill('');
    return (
        <main>
            <section className="game">
                <div className="game__image">
                    <img width='335' height='338' src={IMG1} alt='image'/>
                </div>
                <div className="game__image game__image--mode">
                    <img width='335' height='338' src={IMG2} alt='image' />
                </div>
                <div className="game-container">
                    <div className="game__box">
                        <h1 className="game__title">
                            THE GAME:<br/>
                            <span className="game__title-yellow">A sneak peek</span>
                        </h1>
                    </div>
                </div>
            </section>

            <section className="about-game">
                <div className="about-game__bg">
                    <img width='239' height='241' src={BG1} alt='image' />
                </div>
                <div className="game-container">
                    <div className="about-game__box">
                        <ul className="about-game-list">
                            <li className="about-game-list__item">
                                <div className="about-game-list__image about-game-list__image--mode">
                                            <img width='571' height='484' src={IMG3} alt='image' />
                                </div>
                                <div className="about-game-list__box">
                                    <span className="about-game-list__title">DEGY game is a multiplayer DeFi blockchain game.</span>
                                    <p>Players send their deliverymen on missions and get rewarded with in-game tokens. The more deliverymen you have in your squad, the more missions you can accomplish, and the more is your reward.</p>
                                </div>
                            </li>
                            <li className="about-game-list__item">
                                <div className="about-game-list__image about-game-list__image--mob">
                                    <img width='321' height='623' src={IMG4} alt='image' />
                                </div>
                                <div className="about-game-list__box">
                                    <span className="about-game-list__title">You can spend it on a variety of objects...</span>
                                    <p>...features and other add-ons that will boost your performance and enhance the gameplay. After the game launch, the game token will be listed on exchanges. Players who want to develop faster, will buy tokens from other holders.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="info">
                <div className="game-container">
                    <div className='info-slider'>
                        <Swiper
                            spaceBetween={40}
                            slidesPerView={'auto'}
                            observer={true}
                            observeParents={true}
                            loop={true}
                            autoplay={{
                                delay: 0,
                                disableOnInteraction: false,
                            }}
                            speed={5000}
                            breakpoints={{
                                320: {
                                    spaceBetween: 20
                                },
                                1024: {
                                    spaceBetween: 40
                                }
                            }}
                            modules={[Autoplay]}
                        >
                            <div className='info-slider__container swiper-container'>
                                <ul className='info-slider__wrapper swiper-wrapper'>
                                    {slides.map((text, index) => (
                                        <SwiperSlide key={index} className='info-slider__slide swiper-slide'>
                                            <span className="info-slider__text"><b>Degy</b> GAME</span>
                                        </SwiperSlide>
                                    ))}
                                </ul>
                            </div>
                        </Swiper>

                    </div>
                </div>
            </section>

            <section className="details-game">
                <div className="details-game__bg">
                    <img width='282' height='289' src={BG2} alt='image'/>
                </div>
                <div className="details-game__bg details-game__bg--mode ">
                    <img width='264' height='271' src={BG3} alt='image'/>
                </div>
                <div className="game-container">
                    <div className="details-game__box">
                        <ul className="about-game-list">
                            <li className="about-game-list__item">
                                <div className="about-game-list__image about-game-list__image--details">
                                    <img width='571' height='484' src={IMG5} alt='image'/>
                                </div>
                                <div className="about-game-list__box">
                                    <span className="about-game-list__title">The game is in development at the moment.</span>
                                    <p>After the release, NFT owners will get free access to their first missions. The Game will evolve throughout the time, so that new locations and missions will appear on a regular basis, as well as other cool features.</p>
                                </div>
                            </li>
                            <li className="about-game-list__item about-game-list__item--mode">
                                <div className="about-game-list__image about-game-list__image--cont">
                                    <img width='951' height='621' src={IMG6} alt='image'/>
                                </div>
                                <div className="about-game-list__box">
                                    <span className="about-game-list__title">To play the game...</span>
                                    <p>...you need a device with an Internet connection, a crypto wallet, and at least one DEGY NFT.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="join">
                <div className="game-container">
                    <div className="join__box">
                        <h2 className="join__title">Twitter</h2>
                        <button className="main-button">Join DEGY</button>
                        <div className="join__icon">
                            <img width='248' height='227' src={union} alt="image"/>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}