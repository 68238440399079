import NFT4 from "../../assets/nfts/nft4.png";
import ETH from "../../assets/topimg/eth.svg";
import Close from '../../assets/sprite/close.svg';
import {useEffect, useRef} from "react";

export const FarmModal =  ({isOpen, onClose}) => {
    const modalRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            modalRef.current && !modalRef.current.contains(event.target)
        ) {
            onClose();
        }
    };
    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);
    if (!isOpen) return null;
    return (
        <>
            {/*<div className="overlay" onClick={onClose}></div>*/}

            <div className={isOpen ? "modal is-open" : 'modal'}>
                <div  className={isOpen ? "modal__container modal__container-farm auc__info modal-open" : 'modal__container modal__container-farm auc__info'} data-animation="fade" data-speed="300" data-target="farm" ref={modalRef}>
                    <div className="modal__farm">
                        <div className="modal__close modal-close" onClick={onClose}>
                            <img src={Close} alt="" style={{
                                height: '16px',
                                width: '16px'
                            }}/>
                        </div>

                        <div className="content__card card">
                            <div className="card__image">
                                <img className='card__img' width='211' height='211' src={NFT4} alt='' />
                            </div>
                            <div className="card__info">
                                <div className="card__series">Delivery guys</div>
                                <div className="card__title">Delivery Guy #2195</div>
                                <div className="card__divider"></div>
                                <div className="card__footer">
                                    <div className="card__status uncommon">
                                        <svg  width='12' height='12'>
                                            <use href='img/sprite/sprite.svg#diamond'></use>
                                        </svg>
                                        <span>uncommon</span>
                                    </div>
                                    <div className="card__price">
                                        <img className='card__img' width='211' height='211' src={ETH} alt='' />
                                        <span>0,15</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal__farm-info">
                            <div className="descr">DEGY NFT AUCTION</div>
                            <div className="text">
                                To participate in the DEGY NFT bidding process, Ethereum (ETH) bids are made with the goal of getting onto a dynamic list of bidders. This list is updated in real-time, allowing bidders to track their position and make strategic decisions accordingly. The top 5,555 bidders will receive a DEGY NFT based on their ranking in the list.

                                - Top 55 bidders will secure an Epic NFT.
                                - The subsequent 500 bidders will receive a Rare NFT.
                                - Following that, the next 1,500 bidders will be awarded an Uncommon NFT.
                                - The remaining 3,500 bidders will obtain a Common NFT.

                                The list of bidders is marked with positions indicating the NFT tier each bidder is currently eligible for. Bidders have the opportunity to monitor their position and adjust their bid if they observe a need to strengthen their ranking.

                                It is crucial for bidders to stay informed about their placement on the list as those ranked at position 3,501 and below will not be awarded an NFT. The bidding process operates within a limited time frame, adding a sense of urgency to the competition.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}