import { ethers } from 'ethers';
import { create } from 'zustand';
import { checkAndSwitchNetwork } from '../config/contract/utils';
import { abi_pool, nft_abi_pool, address_pool, nft_address_pool } from '../config/contract/abi';
import { bnbProvider } from '../config/contract/provider';

export const useAuthStore = create((set, get) => ({
    address: null,
    balance: null,
    provider: null,
    signer: null,
    contract: null,
    daiContractWithSigner: null,
    userInfo: [0, 0, 0, 0, 0, 0],
    connectedTo: null,
    board: [],
    isConnectedToWallet: false,

    setWallet: async (address) => {
        const provider = new ethers.BrowserProvider(window.ethereum);
            
        const signer = await provider.getSigner();

        const balance = await provider.getBalance(address);
        
        const ethBalance = ethers.formatEther(balance);

        set({address, provider, signer, balance: ethBalance, connectedTo: 'wallet'});

        localStorage.setItem('address', address);
        localStorage.setItem('connectedTo', 'metamask');
    },

    connectToMetamask: async () => {
        try {
            
            if (!window.ethereum) {
                throw new Error("MetaMask не установлен!");
            }
     
            await checkAndSwitchNetwork();

            const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
            
            const address = accounts[0];

            const provider = new ethers.BrowserProvider(window.ethereum);
            
            const signer = await provider.getSigner();

            const balance = await provider.getBalance(address);
            
            const ethBalance = ethers.formatEther(balance);

            set({address, provider, signer, balance: ethBalance, connectedTo: 'metamask'});
            
            localStorage.setItem('address', address);
            localStorage.setItem('connectedTo', 'metamask');
        } catch (error) {
            console.log(error);
        }
    },

    connectToBinance: async () => {
        try {
            if (typeof window.BinanceChain === "undefined" && !window.ethereum) {
                throw new Error("Binance Chain Wallet не установлен!");
            }

            await checkAndSwitchNetwork();

            const accounts = await window.BinanceChain.request({ method: "eth_requestAccounts" });
            
            const address = accounts[0];

            const provider = new ethers.BrowserProvider(window.ethereum);
            // await provider.send('eth_requestAccounts', []);
            
            const signer = await provider.getSigner();

            const ethBalance = await provider.getBalance(address);
            
            const balance = ethers.formatEther(ethBalance);

            set({address, signer, provider, balance, connectedTo: 'binance'});
            
            localStorage.setItem('account', address);
            localStorage.setItem('connectedTo', 'binance');

        } catch (error) {
            console.log(error);
        }
        
    },

    connectToTrust: async () => {
        try {
            if (typeof window.BinanceChain !== 'undefined' && !window.ethereum) {
                throw new Error("Trust Wallet не установлен!");
            }
    
            // await checkAndSwitchNetwork();
    
            const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
            const account = accounts[0];
            
            const provider = new ethers.BrowserProvider(window.ethereum);
    
            const balance = await provider.getBalance(account);
            const ethBalance = ethers.formatEther(balance);
    
            // const chainId = await provider.getNetwork();
            // if (chainId.chainId !== 56) {  // 56 - для BSC Mainnet, если вам нужно, можно добавить для Testnet 97
            //     console.log("Вы подключены не к нужной сети");
            //     return;
            // }
    
            set({
                address: account,
                provider,
                balance: ethBalance,
                connectedTo: 'metamask'
            });
    
            localStorage.setItem('account', account);
            localStorage.setItem('connectedTo', 'metamask');
            
        } catch (error) {
            console.error(error);
        }

    },

    checkConnection: async () => {
        const {connectToMetamask, connectToBinance} = get();
        // const savedAccount = localStorage.getItem('address');
        const connectedTo = localStorage.getItem('connectedTo');

        // let provider;
        // let signer;

        if (connectedTo === 'metamask') {
            connectToMetamask()
            try {
                // provider = new ethers.BrowserProvider(window.ethereum);
                
                // // await window.ethereum.request({ method: 'eth_requestAccounts' });
                // signer = await provider.getSigner();
                // console.log(signer);

                // const address = signer.address;
                // const signer = await provider.getSigner();
                // const balance = await provider.getBalance(address);
                // const ethBalance = ethers.formatEther(balance);

                // if (savedAccount === address) {
                //     set({ address, provider, signer, balance: ethBalance, connectedTo });
                // } else {
                //     set({ address: null, provider: null, signer: null, balance:null, connectedTo: null });
                // }
            } catch (error) {
                console.error('Error checking connection:', error);
                // set({ user: null, provider: null, connectedTo: null });
            }
        } else if (connectedTo === 'binance' ) {
            try {
                connectToBinance()
                // provider = new ethers.BrowserProvider(window.BinanceChain);
                // // await window.BinanceChain.enable();
                // signer = provider.getSigner();
                // const accounts = await signer.address;

                // if (savedAccount === accounts) {
                //     set({ address: accounts, provider, connectedTo });
                // } else {
                //     set({ address: null, provider: null, connectedTo: null });
                // }
            } catch (error) {
                console.error('Error checking connection:', error);
                // set({ address: null, provider: null });
            }
        }
        
    },

    connectToContract: async () => {
        const {provider, signer, address} = get();
        // console.log(provider, signer, address);
        
        if (!provider) {
            return;
        }
        const contract = new ethers.Contract(address_pool, abi_pool, signer);
        const daiContractWithSigner = contract.connect(signer);
        
        const ethBalance = await provider.getBalance(address);
        
        const balance = ethers.formatEther(ethBalance);
       
        set({contract, daiContractWithSigner, balance});
    },

    connectToPublicProviderContract: async () => {
        const contract = new ethers.Contract(address_pool, abi_pool, bnbProvider);

        try {
            let result = await contract.get_user_board();
            
            result =[result[0], result[1].map((item) => {
                return ethers.formatUnits(item, 18)
            })]

            result = result[0].map((key, index) => {
                return { [key]: parseFloat(result[1][index]) };
            });

            result.sort((a, b) => Object.values(b)[0] - Object.values(a)[0]);

            set({board: result})
        } catch (error) {
            console.error('Error calling get_user_board:', error);
        }

        set({contract});
    },

    // Получить лидерборд
    getUserBoard: async () => {
        const {contract, provider, address} = get();
        if (!contract ) {
            return;
        }

        try {
            let result = await contract.get_user_board();

            result =[result[0], result[1].map((item) => {
                return ethers.formatUnits(item, 18)
            })]

            result = result[0].map((key, index) => {
                return { [key]: parseFloat(result[1][index]) };
            });

            result.sort((a, b) => Object.values(b)[0] - Object.values(a)[0]);

            const ethBalance = await provider.getBalance(address);
            
            const balance = ethers.formatEther(ethBalance);
           
            set({board: result, balance})
        } catch (error) {
            console.error('Error calling get_user_board:', error);
        }
    },

    investInDEGY: async (ref="0x0000000000000000000000000000000000000000", amount) => {
        const {daiContractWithSigner} = get();
        
        try {
            const tx  = await daiContractWithSigner.Invest_in_DEGY(ref, { value: ethers.parseUnits(amount.toString(10), "ether") });
            console.log('investInDEGYdaiContractWithSigner', tx);

            return tx;
        } catch (err) {
            console.info('err in transaction', err.message);
        }
    },

    // Получить инфо о юзере
    // user - адрес юзера
    // response array of tuple
    // tuple(BigNumber - инвестиция юзера,
    // int - кол-во points,
    // int - кол-во рефералов на 1й линии,
    // int - кол-во рефералов на 2й линии,
    // BigNumber - инвестиции пришедшие от рефералов, int - место в таблице)
    getUserInfo: async (address) => {
        const {contract} = get();
        
        try{
            const response = await contract.get_user_info(address);
            let userInfo = response.map((info) => {
                return ethers.formatUnits(info, 18)
            })

            set({userInfo})
        }
        catch (err) {
            console.info('err', err.message);
        }
    },

    // Получить инвестиции обратно в конце аукциона(работает только
    // если человек не вошел в ТОП)
    getInvestBack: async () => {
        const {daiContractWithSigner} = get();
        console.log('getInvestBackContract', daiContractWithSigner)
        try{
            const tx = await daiContractWithSigner.get_invest_back();
            console.log('invest back', tx)

            return tx;
        }
        catch (err) {
            console.info('err', err.message);
        }
    },

    // Получить NFT(работает только в конце аука)
    getMyNft: async () => {
        const {daiContractWithSigner} = get();
        console.log('contract getNft',daiContractWithSigner)
        
        try{
            const tx = await daiContractWithSigner.get_my_NFT();
            console.log('tx getNft',tx)

            return tx;
        }
        catch (err) {
            console.info('err', err.message);
        }
    },

    getWalletNFTs: async () => {
        const {provider, signer, address} = get();
        console.log(provider, signer, address);
        
        if (!provider) {
            return;
        }

        const contract = new ethers.Contract(nft_address_pool, nft_abi_pool, signer);

        const balance = await contract.balanceOf(address);
        console.log('balance', balance);

        const tokenIds = [];
        for (let i = 0; i < balance; i++) {
            const tokenId = await contract.tokenOfOwnerByIndex(address, i);
            console.log(tokenId);
            
            tokenIds.push(tokenId.toString());
        }

        return tokenIds;
    },
    logout: () => {
        set({address: null, provider: null, signer:null, connectedTo: null});
    }
}))