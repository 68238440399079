import ETH from '../../assets/eth.png';
import Blast from '../../assets/topimg/blast.png';
import Close from '../../assets/sprite/close.svg';
import {useEffect, useRef, useState} from "react";
import Loader from '../../assets/loader.svg';
import Success from '../../assets/success.svg';

export const PlaceABidModal = ({isOpen, onClose, invest, balance, getUserBoard, getUserInfo}) => {
    const [inputValue, setInputValue] = useState('');
    const [modalState, setModalState] = useState('')
    const ref = new URLSearchParams(window.location.search).get('ref') || "0x0000000000000000000000000000000000000000";
    const modalRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            modalRef.current && !modalRef.current.contains(event.target)
        ) {
            onClose();
        }
    };

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await invest(ref, inputValue);

            if(response) {
                setModalState('load')
            }
            const receipt = await response.wait();
            if(receipt) {
                setModalState('success')
                getUserBoard()
                getUserInfo()
            } else {
                setModalState('error')
            }
        } catch {
            setModalState('error')
        }

    }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <>
            {/*<div className="overlay" onClick={onClose}></div>*/}
            <form className={isOpen ? "modal is-open" : 'modal'} onSubmit={handleSubmit} >
                <div  className={isOpen ? "modal__container modal-open fade animate-open" : 'modal__container'} data-animation="fade" data-speed="300" data-target="mint" ref={modalRef}>
                    {modalState === '' && <div className="modal__mint">
                        <div className="modal__head">
                            <div className="modal__title">Place a bid</div>
                            <div className="modal__close modal-close" onClick={onClose}>
                                <img src={Close} alt="" style={{
                                    height: '16px',
                                    width: '16px'
                                }}/>
                            </div>
                        </div>

                        <div className="modal__inner-mint">
                            <div className="modal__text"><span>You are about to place a bid for DEGY NFT Auction from Delivery Guys NFT Collection</span>
                            </div>

                            <ul className="modal__mint-total">
                                <li className="modal__mint-line">
                                    <div className="modal__img">
                                        <img className='card__img' width='211' height='211' src={Blast} alt='' />
                                    </div>

                                    <div className="modal__total-checkout">
                                        <div className="blast-wallet">0xs676a9a...4b8E</div>
                                        <div><span className="card__total-price card__price">Blast</span></div>
                                    </div>
                                    <div className="blast-con">Connected</div>
                                </li>
                            </ul>
                            <div>
                                <div className="class-ir">Your bid</div>
                                <div className="ir-cont">
                                    <img className="card__img" width="20" height="20" src={ETH} alt="" />
                                    <input className="blast-link"
                                           placeholder={balance} type="number"
                                           id="quantity-js"
                                           required
                                           value={inputValue} onChange={handleChange} />
                                </div>
                                <div className="ir-last">
                                    <span>ETH in your wallet</span>
                                    <span>{balance} ETH</span>
                                </div>
                            </div>

                            <button
                                className="checkout"
                                data-path="waitWindow"
                                type="submit"
                            >
                                Confirm the bid
                            </button>
                        </div>
                    </div>}

                    {modalState === 'load' && <div className="modal__mint load-modal">
                        <div className="modal__close modal-close" onClick={onClose}>
                            <img src={Close} alt="" style={{
                                height: '16px',
                                width: '16px'
                            }}/>
                        </div>
                        <img src={Loader} alt="" className="modal__loader load"/>
                        <div>
                            <p className="modal__load-title">Transaction is being…</p>
                            <p className="modal__load-text">processed on the blockchain, please wait.</p>
                        </div>

                    </div>
                    }

                    {modalState === 'success' &&
                        <div className="modal__mint load-modal">
                            <div className="modal__close modal-close" onClick={onClose}>
                                <img src={Close} alt="" style={{
                                    height: '16px',
                                    width: '16px'
                                }}/>
                            </div>
                            <img src={Success} alt="" className="modal__loader"/>
                            <div>
                                <p className="modal__load-title">Transaction has been successfully…</p>
                                <p className="modal__load-text">sent and is now being confirmed</p>
                            </div>
                            <div className="modal__button" onClick={onClose}>
                                OK
                            </div>
                        </div>
                    }

                    {modalState === 'error' &&
                        <div className="modal__mint load-modal">
                            <div className="modal__close modal-close" onClick={onClose}>
                                <img src={Close} alt="" style={{
                                    height: '16px',
                                    width: '16px'
                                }}/>
                            </div>
                            <div>
                                <p className="modal__load-title">something went wrong</p>
                            </div>
                        </div>
                    }
                </div>
            </form>
        </>
    )
}