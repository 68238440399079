import { ethers } from "ethers";

export const bscTestnet = {
    id: 97,
    name: 'BNB Smart Chain Testnet',
    network: 'BNB Smart Chain Testnet',
    nativeCurrency: {
        decimals: 18,
        name: 'BNB',
        symbol: 'tBNB',
    },
    rpcUrls: {
        public: { http: ['https://data-seed-prebsc-1-s1.binance.org:8545/'] },
        default: { http: ['https://data-seed-prebsc-1-s1.binance.org:8545/'] },
    },
    blockExplorers: {
        etherscan: { name: 'SnowTrace', url: 'https://testnet.bscscan.com/' },
        default: { name: 'SnowTrace', url: 'https://testnet.bscscan.com/' },
    }
};

export const bnbProvider = new ethers.JsonRpcProvider("https://data-seed-prebsc-1-s1.binance.org:8545/");
